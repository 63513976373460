import { FnRun as ExportKitAssayAssignments } from "./fn-gtm-export-kit-assay-assignments";

import { FnRun as ExportOrders } from "./fn-gtm-export-orders";

import { FnRun as ExportRerunRequests } from "./fn-gtm-export-rerun-requests";

import { FnRun as ExportSeqSelection } from "./fn-gtm-export-seq-selection";

import { FnRun as ImportKitsReceived } from "./fn-gtm-import-kits-received";

import { FnRun as ImportOrderFulfillments } from "./fn-gtm-import-order-fulfillments";

import { FnRun as RunCancelOrder } from "./fn-gtm-run-cancel-order";

import { FnRun as RunKitAssays } from "./fn-gtm-run-kit-assays";

import { FnRun as RunPushSeqSels } from "./fn-gtm-run-push-seq-selections";

import { FnRun as RunPullKitsReceived } from "./fn-gtm-run-pull-kits-received";

import { FnRun as RunPullOrdersFulfilled } from "./fn-gtm-run-pull-orders-fulfilled";

const pages = {
  "gtm-export-kit-assay-assignments": ExportKitAssayAssignments,
  "gtm-export-orders": ExportOrders,
  "gtm-export-rerun-requests": ExportRerunRequests,
  "gtm-export-seq-selection": ExportSeqSelection,
  "gtm-import-kits-received": ImportKitsReceived,
  "gtm-import-order-fulfillments": ImportOrderFulfillments,
  "gtm-run-cancel-order": RunCancelOrder,
  "gtm-run-kit-assays": RunKitAssays,
  "gtm-run-push-seq-selections": RunPushSeqSels,
  "gtm-run-pull-kits-received": RunPullKitsReceived,
  "gtm-run-pull-orders-fulfilled": RunPullOrdersFulfilled,
};

export default pages;
