import { Form, DatePicker } from "antd";
import { GTMolecularRunFn } from "../../components/GTMolecularRunFn";

const FN_NAME = "gtm-run-pull-orders-fulfilled";

let pullOrdersFulfilledFormItems = (
  <>
    <Form.Item label="Date" name="date" required="true">
      <DatePicker />
    </Form.Item>
  </>
);

function FnRun() {
  return (
    <GTMolecularRunFn name={FN_NAME} formItems={pullOrdersFulfilledFormItems} />
  );
}

export { FnRun };
